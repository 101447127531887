import { FC, useEffect, useMemo, useState } from "react";
import { Reservation, useApi } from "../api/shareparkApi";
import {
  Col,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import "../custom.css";
import ButtonWrapper from "./ButtonWrapper";
import { useNavigate } from "react-router-dom";
import { Profile } from "../auth/authContext";
import { useForm, SubmitHandler } from "react-hook-form";

function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}

const inOneHour = new Date(Date.now() + 60 * 60 * 1000);

/**
 * Converts a date to a string in the format "YYYY-MM-DD"
 */
const toLocStrDate = (date: Date): string => {
  return date.toLocaleDateString()
              .split("/")
              .reverse()
              .join("-");
}

/**
* Converts a date to a string in the format "HH:MM"
*/
const toLocStrTime = (date: Date): string => {
  return date.toLocaleTimeString(undefined, {hour12: false})
              .split(":")
              .slice(0, 2)
              .join(":");
}

// loop and create times for every 15 minutes
const times = [] as string[];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 15) {
    const hour = i < 10 ? "0" + i : i;
    const minute = j < 10 ? "0" + j : j;

    times.push(`${hour}:${minute}`);
  }
}

const defaultValues: Reservation = {
  city: "",
  vehicleId: "",
  siteId: "",
  siteName: "",
  // set default date and time to australia date and time one hour from now
  startDate: toLocStrDate(new Date(Date.now())),
  startTime: toLocStrTime(new Date(Date.now())),

  // set end time to 1 hour from now
  endDate: toLocStrDate(inOneHour),
  endTime: toLocStrTime(inOneHour),

  status: "Pending",
  price: 0,
};

const ReservationDetails: FC<{
  reservation: Reservation;
  profile: Profile;
}> = ({ reservation, profile }) => {
  const navigate = useNavigate();
  const { createReservation, cancelReservation, getStatusAndPrice } = useApi();
  const readOnly = reservation?.id ? true : false;

  const [lastValues, setLastValues] = useState<Reservation>(defaultValues);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm<Reservation>({ defaultValues: reservation, values: reservation });

  const watchAllFields = watch();

  const startDate = watch("startDate") || defaultValues.startDate;
  const startTime = watch("startTime") || defaultValues.startTime;
  const endDate = watch("endDate") || defaultValues.endDate;
  const endTime = watch("endTime") || defaultValues.endTime;
  
  const start = useMemo(() => new Date(startDate + " " + startTime), [startDate, startTime]);
  const end = useMemo(() => new Date(endDate + " " + endTime), [endDate, endTime]);

  useEffect(() => {
    if (readOnly) return;

    // if start + 1 hour is after end, set end to start + 1 hour (and the end time hasn't been changed already)
    if (start.getTime() + 60 * 60 * 1000 > end.getTime() && !(isDirty && dirtyFields.endTime)) {
      setValue(
        "endDate",
        toLocStrDate(new Date(start.getTime() + 60 * 60 * 1000))
      );

      setValue(
        "endTime",
        toLocStrTime(new Date(start.getTime() + 60 * 60 * 1000))
      );
    }
  }, [dirtyFields.endTime, end, isDirty, readOnly, setValue, start]);

  useEffect(() => {
    if (readOnly) return;

    var values = getValues();

    if (
      values.city &&
      values.vehicleId &&
      values.siteId &&
      values.startDate &&
      values.startTime &&
      values.endDate &&
      values.endTime
    ) {
      // check to see if any of the relevant values have changed
      if (
        values.city === lastValues.city &&
        values.vehicleId === lastValues.vehicleId &&
        values.siteId === lastValues.siteId &&
        values.startDate === lastValues.startDate &&
        values.startTime === lastValues.startTime &&
        values.endDate === lastValues.endDate &&
        values.endTime === lastValues.endTime
      ) {
        return;
      }

      setLastValues(values);

      getStatusAndPrice(values).then((result) => {
        if (result) {
          setValue("status", result.status);
          setValue("price", result.price);
          setValue("error", result.error);
        }
      });
    }
  }, [
    getStatusAndPrice, 
    getValues, 
    lastValues.city, 
    lastValues.endDate, 
    lastValues.endTime, 
    lastValues.siteId, 
    lastValues.startDate, 
    lastValues.startTime, 
    lastValues.vehicleId, 
    readOnly, 
    setValue, 
    watchAllFields
  ]);

  const onSubmit: SubmitHandler<Reservation> = (data) => {
    createReservation(data).then(() => navigate("/reservations"));
  };

  const cancel = () => {
    cancelReservation(reservation).then(() => navigate("/reservations"));
  };

  const formatCurrency = (value: number | undefined) => {
    if (!value) return "";

    return value.toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
      minimumFractionDigits: 2,
    });
  };

  const watchCity = watch("city") || reservation?.city;
  const price = watch("price") || reservation?.price;

  const [endTimes, setEndTimes] = useState<string[]>([]);
  const [startTimes, setStartTimes] = useState<string[]>([]);


  useEffect(() => {
    const startTimesValues = times.filter((time) => {
      const timeDate = new Date(startDate + " " + time);
      return timeDate.getTime() > new Date().getTime();
    });
    setStartTimes(startTimesValues);
    if (
      (endDate ?? toLocStrDate(inOneHour)) < (startDate ?? toLocStrDate(new Date(Date.now())))
      ) {
        setValue("endDate", startDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, setValue]);

  useEffect(() => {
    setValue("startTime", startTimes[0]);
  }, [startTimes, setValue]);

  useEffect(() => {
    // Provide values from start time
    const endTimesValues = times.filter((time) => {
      const timeDate = new Date(endDate + " " + time);
      return timeDate.getTime() >= new Date().getTime();
    });
    setEndTimes(endTimesValues);
  }, [endDate, setEndTimes]);

  useEffect(() => {
    // Default current end time value to an hour from start time
    setValue("endTime", endTimes[4]);
  }, [endTimes, setValue]);

  const formatTime = (time: string) => {
    // take in string like 12:00 and return 12:00 PM
    const hour = parseInt(time.split(":")[0]);
    const minute = parseInt(time.split(":")[1]);
    let minuteString = time.split(":")[1];

    // ensure leading zero on minute
    if (minute < 10) {
      minuteString = `0${minute}`;
    }

    if (hour < 12) {
      return `${hour}:${minuteString} AM`;
    }

    if (hour === 12) {
      return `${hour}:${minuteString} PM`;
    }

    return `${hour - 12}:${minuteString} PM`;
  };

  return (
    <Modal
      show={true}
      dialogClassName="modalReservationEditForm"
      centered
      backdrop="static"
    >
      <Modal.Header style={{ backgroundColor: "white" }}>
        <Col>
          <h2>Reservation</h2>
        </Col>
        <Col xs={3} className="text-end">
          <ButtonWrapper
            buttonText="Close x"
            fullWidth={true}
            onClick={() => navigate("/reservations")}
          />
        </Col>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="formStyle">
          <FormGroup>
            <FormLabel>City</FormLabel>
            <FormSelect
              {...register("city")}
              defaultValue={reservation?.city}
              disabled={readOnly}
            >
              <option value={""}>Choose a City</option>
              {profile.publicSites
                .map((site) => site.city)
                .filter(onlyUnique)
                .map((city, i) => {
                  return <option key={`city-${i}`}>{city}</option>;
                })}
            </FormSelect>
          </FormGroup>

          <FormGroup>
            <FormLabel>Vehicle</FormLabel>
            <FormSelect {...register("vehicleId")} disabled={readOnly}>
              {profile.vehicles.map((vehicle, i) => {
                return (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.plate}
                  </option>
                );
              })}
            </FormSelect>
          </FormGroup>

          <FormGroup>
            <FormLabel>Site</FormLabel>
            <FormSelect {...register("siteId")} disabled={readOnly}>
              <option value={""}>Choose a Site</option>
              {profile.publicSites
                .filter((_) => _.city === watchCity)
                .map((site, i) => {
                  return (
                    <option key={site.siteId} value={site.siteId}>
                      {site.siteName}
                    </option>
                  );
                })}
            </FormSelect>
          </FormGroup>

          <Row>
            <Col>
              <FormGroup>
                <FormLabel>Start Date</FormLabel>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={defaultValues.startDate}
                  disabled={readOnly}
                  min={toLocStrDate(new Date())}
                  {...register("startDate")}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Start Time</FormLabel>
                {/* <input
                  className="form-control"
                  type="time"
                  defaultValue={defaultValues.startTime}
                  disabled={readOnly}
                  {...register("startTime")}
                /> */}

                <select
                  className="form-control"
                  {...register("startTime")}
                  disabled={readOnly}
                >
                  {startTimes.map((time, i) => {
                    return (
                      <option key={`time-${i}`} value={time}>
                        {formatTime(time)}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <FormLabel>End Date</FormLabel>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={defaultValues.endDate}
                  disabled={readOnly}
                  min={toLocStrDate(start)}
                  {...register("endDate")}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>End Time</FormLabel>
                {/* <input
                  className="form-control"
                  type="time"
                  defaultValue={defaultValues.endTime}
                  disabled={readOnly}
                  {...register("endTime")}
                /> */}

                <select
                  className="form-control"
                  {...register("endTime")}
                  disabled={readOnly}
                >
                  {endTimes.map((time, i) => {
                    return (
                      <option key={`time-${i}`} value={time}>
                        {formatTime(time)}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <FormLabel>Status</FormLabel>
            <label className={"status " + (getValues("error") ? "" : "error")}>
              {getValues("error") ? getValues("error") : getValues("status")}
            </label>
          </FormGroup>

          <FormGroup>
            <FormLabel>Price</FormLabel>
            <label className="status">{formatCurrency(price)}</label>
          </FormGroup>

          {!readOnly && (
            <div className="mt-3">
              <input
                disabled={
                  !!getValues("error") || getValues("status") !== "Pending"
                }
                type="submit"
                className="orangeButton"
              />
            </div>
          )}
        </form>

        {readOnly && (
          <Col xs={8}>
            <ButtonWrapper
              buttonText="Cancel Reservation"
              fullWidth={true}
              onClick={() => cancel()}
            />
          </Col>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReservationDetails;
