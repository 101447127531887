import React, { FC, useMemo, useRef, useState } from "react";
import { GoogleMap, InfoBox, Marker } from "@react-google-maps/api";
import { Site as SiteType, useApi } from "../api/shareparkApi";

const defaultProps = {
  center: {
    // sydney
    lat: -33.91277075825478,
    lng: 151.20202097822738,
  },
  zoom: 10,
};

const SiteMap: FC<{
  apiKey: string;
  sites: SiteType[];
  setSelectedSite: (id: string) => void;
}> = ({ sites, setSelectedSite }) => {
  return (
    <GoogleMap
      mapContainerStyle={{ height: "50vh", width: "100%" }}
      options={{
        scrollwheel: true,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "landscape",
            stylers: [{ visibility: "on" }],
          },
        ],
      }}
      {...defaultProps}
    >
      {/* <Site site={sites[0]} onClick={() => setSelectedSite(sites[0].siteId)} /> */}

      {sites.map((site) => (
        <Site
          key={site.siteId}
          site={site}
          onClick={() => setSelectedSite(site.siteId)}
        />
      ))}
    </GoogleMap>
  );
};

const Site: FC<{ site: SiteType; onClick: () => void }> = ({
  site,
  onClick,
}) => {

  const [hoveredMarker, setHoveredMarker] = useState(false);
  const infoBoxRef = useRef<InfoBox>(null);
  const iconSymbol = useMemo(
    () => ({
      url: "/images/shareparkSquareLogoMapIcon.svg",
      scaledSize: new window.google.maps.Size(50, 50),
    }),
    []
  );

  return (
    <Marker
      position={new google.maps.LatLng(site.latitude, site.longitude)}
      icon={iconSymbol}
      onClick={() => onClick()}
      onMouseOver={() => setHoveredMarker(true)}
      onMouseOut={() => setHoveredMarker(false)}
    >
      {hoveredMarker && (
        <InfoBox
          key={`${hoveredMarker}`}
          ref={infoBoxRef}
          options={{
            visible: hoveredMarker === true,
            position: new google.maps.LatLng(site.latitude, site.longitude),
            boxStyle: {
              backgroundColor: "#fffacd",
              padding: "12px",
            },
            pixelOffset: new window.google.maps.Size(25, -50),
            closeBoxMargin: "0",
            closeBoxURL: "",
          }}
        >
          <div>
            <div>
              <strong>{site.siteName}</strong>
            </div>
            <div>{site.city}</div>
            <div>
              <label style={{ marginRight: "1em" }}>Capacity: </label>
              {site.currentCapacity}
            </div>
          </div>
        </InfoBox>
      )}
    </Marker>
  );
};

export default SiteMap;
