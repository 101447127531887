import React, { useState, useEffect, FC, ComponentProps } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import QRCode from "qrcode.react";
import "../custom.css";
import ButtonWrapper from "./ButtonWrapper";
import { useProfile } from "../auth/authContext";
import type {
  Reservation as ReservationType,
  SiteParker,
} from "../api/shareparkApi";
import { useApi } from "../api/shareparkApi";
import SiteMap from "./SiteMap";
import { useNavigate, useParams } from "react-router-dom";
import ReservationDetails from "./ReservationDetails";

const Reservations = () => {
  const profile = useProfile();
  let { config } = useApi();

  const [selectedSite, setSelectedSite] = useState<string | null>(null);

  const chooseSite = (id: string) => {
    setSelectedSite(id);
  };

  return (
    <Container className="px-5">
      <Row className="gx-2 my-5">
        <Col lg className="pb-5 pb-lg-0 ">
          <QRCodeSection />
        </Col>
        <Col lg>
          <YourReservations selectedSite={selectedSite} />
        </Col>
      </Row>
      <Row className="gx-2 align-items-center my-5">
        <Col lg></Col>
        <Col lg>
          <ParkingLocations />
        </Col>
      </Row>
      {config?.googleMapsApi && (
        <Row className="pb-3">
          <Col>
            <SiteMap
              apiKey={config?.googleMapsApi}
              sites={profile.publicSites}
              setSelectedSite={chooseSite}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

const QRCodeSection = () => {
  const profile = useProfile();
  const { reissueQrCode } = useApi();

  return (
    <Container>
      <Row className="align-items-center">
        <Col>
          <h2>Your QR Code</h2>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col className="mb-4 mb-md-0" style={{ maxWidth: "170px" }}>
          {profile.parker.uid && (
            <QRCode
              value={profile.parker.uid}
              size={148}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
          )}
        </Col>
        <Col xl={5} md={6} className="gx-3" style={{ minWidth: "185px" }}>
          <ButtonWrapper
            buttonText="Re-issue QR Code"
            fullWidth={true}
            onClick={() => reissueQrCode()}
          />
        </Col>
      </Row>
    </Container>
  );
};

const YourReservations: FC<{
  selectedSite: string | null;
}> = ({ selectedSite }) => {
  const profile = useProfile();

  let { id } = useParams();
  const navigate = useNavigate();

  const [reservations, setReservations] = useState<ReservationType[]>([]);

  useEffect(() => {
    if (profile && profile.reservations) {
      setReservations(profile.reservations);
    }
  }, [profile]);

  const onReservationClicked = (reservation: ReservationType) => {
    navigate(`/reservations/${reservation.id}`);
  };

  const showNewEditForm = () => {
    navigate(`/reservations/00000000-0000-0000-0000-000000000000`);
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h2>Your Reservations</h2>
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            {profile.parker.stripeMetadata !== null ||
            profile.permSites.length > 0 ? (
              <div style={{ width: "110px" }} className="mb-3">
                <ButtonWrapper
                  buttonText="+ Add"
                  fullWidth={true}
                  onClick={() => showNewEditForm()}
                />
              </div>
            ) : (
              <div className="alert alert-danger">
                Please add a payment method first
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {reservations.map((reservation) => (
              <Reservation
                reservation={reservation}
                key={reservation.id}
                onReservationClicked={() => onReservationClicked(reservation)}
              />
            ))}
            {reservations.length === 0 && <div>You have no reservations</div>}
          </Col>
        </Row>
      </Container>
      {id && (
        <ReservationDetails
          reservation={reservations.find((r) => r.id === id) as ReservationType}
          profile={profile}
        />
      )}
    </>
  );
};

const Reservation: FC<{
  reservation: ReservationType;
  onReservationClicked: () => void;
}> = ({ reservation, onReservationClicked }) => {
  const startDate = reservation.startDate
    ? new Date(reservation.startDate)
    : new Date();
  const endDate = reservation.endDate
    ? new Date(reservation.endDate)
    : new Date();

  const dateSuffix = (d: number) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <Container
      className="g-3 bg-white reservation curserPointer"
      onClick={() => onReservationClicked()}
    >
      <div className="text-wrap">{reservation.siteName}</div>
      <div className="dateString text-wrap">
        {startDate.toLocaleDateString("en-AU", {
          weekday: "short",
          day: "numeric",
        }) + dateSuffix(startDate.getDate())}
        &nbsp;
        {startDate.toLocaleDateString("en-AU", {
          month: "long",
          year: "numeric",
        })}
        ,&nbsp;{getTimeString(reservation.startTime)}
      </div>
      <div className="dateString text-wrap">
        {endDate.toLocaleDateString("en-AU", {
          weekday: "short",
          day: "numeric",
        }) + dateSuffix(endDate.getDate())}
        &nbsp;
        {endDate.toLocaleDateString("en-AU", {
          month: "long",
          year: "numeric",
        })}
        ,&nbsp;{getTimeString(reservation.endTime)}
      </div>

      <Row className="align-items-center">
        <Col md={6}>
          <Container className="g-2">
            {reservation.price ? reservation.price.toFixed(2) : "-"}
          </Container>
        </Col>
        <Col md={6}>{reservation.status}</Col>
        {reservation.error ? (
          <Col md={12} className="p-2">
            <div className="alert alert-danger m-2">{reservation.error}</div>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

const ParkingLocations = () => {
  let profile = useProfile();

  return (
    <Container>
      <h2>Your Permanent Parking Locations</h2>
      <Container className="g-0">
        {profile.permSites
          .filter((s) => s.accessTimes.length > 0)
          .map((siteParker) => (
            <ParkingLocation siteParker={siteParker} key={siteParker.id} />
          ))}
        {profile.permSites.filter((s) => s.accessTimes.length > 0).length ===
          0 && <div>You have no permanent parking locations</div>}
      </Container>
    </Container>
  );
};

const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

const ParkingLocation: FC<{ siteParker: SiteParker }> = ({ siteParker }) => {
  const getTimeString = (time: number) => {
    if (time === 0) {
      return "Midnight";
    }

    if (time === 24) {
      return "Midnight";
    }

    if (time < 12) {
      return time + " AM";
    }

    if (time === 12) {
      return time + " PM";
    }

    return time - 12 + " PM";
  };

  const accessDays = siteParker.accessTimes;

  return (
    <Container className="g-3 my-3 py-2 bg-white reservation">
      <Row>
        <Col>{siteParker.siteName}</Col>
      </Row>
      <Row>
        <Col>{siteParker.siteAddress}</Col>
      </Row>

      {siteParker.areas.map((area, i) => {
        return (
          <div key={area.id}>
            {siteParker.areas.length > 1 && (
              <Row>
                <Col>Area: {area.name}</Col>
              </Row>
            )}

            <Row>
              <Col>
                <Table borderless size="sm" className="accessTimes">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessDays
                      .filter((acc) => acc.areaId === area.id)
                      .map((acc) => (
                        <tr key={acc.id}>
                          <td>{dayOfWeek[acc.dayOfWeek]}</td>
                          {acc.start === 0 && acc.end === 24 && (
                            <td>All Day</td>
                          )}
                          {acc.start === acc.end && (
                            <td className="text-danger">Not allowed</td>
                          )}
                          {(acc.start !== 0 || acc.end !== 24) &&
                            acc.start !== acc.end && (
                              <td>
                                {getTimeString(acc.start)} -{" "}
                                {getTimeString(acc.end)}
                              </td>
                            )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

const getTimeString = (time: string | undefined) => {
  if (!time) return;

  if (time < "12:00") {
    return time + " AM";
  }

  if (time < "13:00") {
    return time + " PM";
  }

  const timeFragments = time.split(":");
  const newTime =
    Number(timeFragments[0]) - 12 + ":" + timeFragments[1] + " PM";
  return newTime;
};

const changeTime = (date: Date | string, time: string, hours: number) => {
  const newDate = new Date(date);
  let newDateString: string = newDate.toISOString().split("T")[0];
  const timeFragments = time.split(":");
  let newHours = parseInt(timeFragments[0]) + hours;

  if (newHours < 0) {
    newHours = 23;
    newDate.setDate(newDate.getDate() - 1);
    newDateString = newDate.toISOString().split("T")[0];
  } else if (newHours >= 24) {
    newHours = 0;
    newDate.setDate(newDate.getDate() + 1);
    newDateString = newDate.toISOString().split("T")[0];
  }

  const newHourString = newHours < 10 ? "0" + newHours : newHours;
  const newTime = newHourString + ":" + timeFragments[1];

  return [newDateString, newTime];
};

export default Reservations;
