import React, { FC, useState } from "react";
import { TabContainer, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Image from "react-bootstrap/Image";
import ButtonWrapper from "./ButtonWrapper";
import Reservations from "./Reservations";
import Details from "./Details";
import Vehicles from "./Vehicles";
import Reports from "./Reports";
import { useAuth } from "../auth/authContext";
import "../custom.css";
import { ProfileComplete } from "./ProfileComplete";
import { useParams, useNavigate } from "react-router-dom";

export const Layout: FC = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const { logout, profile, sendEmailVerification, currentUser } = useAuth();
  const [verificationSent, setVerificationSent] = useState(false);

  const verify = async () => {
    await sendEmailVerification();
    setVerificationSent(true);
  };

  return (
    <div className="d-flex flex-column overflow-hidden min-vh-100">
      <Container className="nav-justified g-0 flex-column flex-md-row px-5 py-3">
        <Row className="h-100 align-items-center px-2">
          <Col>
            <Image src={"/images/sharepark-logo.svg"} width="180px" />
          </Col>
          <Col className="text-end">
            <ButtonWrapper buttonText="Log Out" onClick={logout} />
          </Col>
        </Row>
      </Container>

      {profile?.parker?.email != null ? (
        <>
          <ProfileComplete profile={profile} />

          <Tabs
            activeKey={tab}
            defaultActiveKey="reservations"
            onSelect={(nextTab) => navigate("/" + nextTab)}
            className="container nav-justified tabs g-0 flex-column flex-md-row px-5"
          >
            <Tab
              eventKey="reservations"
              title="QR Code &#38; Reservations"
              className="flex-grow-1 overflow-hidden text-nowrap"
            >
              <TabContainer>
                {tab === "reservations" && <Reservations />}
              </TabContainer>
            </Tab>
            <Tab
              eventKey="details"
              title="Details"
              className="flex-grow-1 overflow-hidden text-nowrap"
            >
              <TabContainer>{tab === "details" && <Details />}</TabContainer>
            </Tab>
            <Tab
              eventKey="vehicles"
              title="Vehicles"
              className="flex-grow-1 overflow-hidden text-nowrap"
            >
              <TabContainer>{tab === "vehicles" && <Vehicles />}</TabContainer>
            </Tab>
            <Tab
              eventKey="reports"
              title="Reports"
              className="flex-grow-1 overflow-hidden text-nowrap"
            >
              <TabContainer>{tab === "reports" && <Reports />}</TabContainer>
            </Tab>
          </Tabs>
        </>
      ) : (
        <div
          style={{ flexGrow: 1, backgroundColor: "#f2f2f2" }}
          className="d-flex justify-content-center align-items-center"
        >
          {!currentUser?.emailVerified && ( // only show if firebase userprofile is unverified
            <div className="text-center">
              {verificationSent ? (
                <p>Check your email and click on the link provided</p>
              ) : (
                <>
                  <h6>
                    Please verify your email address:{" "}
                    <strong>{currentUser?.email}</strong>
                  </h6>
                  <div className="mt-3">
                    <button className="orangeButton" onClick={verify}>
                      Send Email Verification
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}

      <div className="footer">
        <Container className="mt-auto nav-justified g-0 flex-column flex-md-row px-5 py-2">
          <Row>
            <Col xs="auto">
              <Image src={"/images/shareparkSquareLogo.svg"} width="38px" />
            </Col>
            <Col>
              <div>
                <a
                  style={{ color: "#fe9015" }}
                  href="mailto:preisner@parkagility.com"
                >
                  Contact Us
                </a>
              </div>
              <div>Account Support</div>
              <div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://assets.website-files.com/5dce688264679143b99e2794/5f4316c56896716e3488e558_SharePark%20Service%20-%20Terms%20of%20Use%20Aug%202020.pdf"
                >
                  Terms of Service
                </a>
              </div>
              <div>Copyright © {new Date().getFullYear()} Share Park</div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
